<template>
    <div class="wrapper assetUse">
        <table class="custom-table">
            <thead>
                <tr>
                    <td class="custom-th custom-th-bg" style="width:0.8rem;">
                        序号
                    </td>
                    <td class="custom-th custom-th-bg">
                        <table>
                            <tbody>
                                <tr>
                                    <td style="width:1.5rem;">
                                        品种
                                    </td>
                                    <td style="width:1.5rem;">
                                        项目
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in assetUseSTData">
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.date>0">
                            {{ item.date }}月
                        </td>
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.date==0">
                            总计
                        </td>
                    </template>
                </tr>
            </thead>
            <tbody class="border6">
                <tr>
                    <td rowspan="22" class="custom-th-even">
                        产能情况
                    </td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <th rowspan="11" style="width:1.5rem;">
                                        商砼产能
                                    </th>
                                    <th style="width:1.5rem;">
                                        生产线数量（条）
                                    </th>
                                </tr>
                                <tr>
                                    <th>运行（条）</th>
                                </tr>
                                <tr>
                                    <th>闲置（条）</th>
                                </tr>
                                <tr>
                                    <th>生产线能力（万方）</th>
                                </tr>
                                <tr>
                                    <th>运行（万方）</th>
                                </tr>
                                <tr>
                                    <th>闲置（万方）</th>
                                </tr>
                                <tr>
                                    <th>生产线价值（万元）</th>
                                </tr>
                                <tr>
                                    <th>运行（万元）</th>
                                </tr>
                                <tr>
                                    <th>闲置（万元）</th>
                                </tr>
                                <tr>
                                    <th>总产能利用率（%）</th>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in assetUseSTData">
                        <td :key="index" v-if="index<assetUseSTData.length-1">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td @click="showDataDrills(item,1,'concrete')">
                                            <a href="javascript:;">{{ item.number }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,2,'concrete')">
                                            <a href="javascript:;">{{ item.number_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,3,'concrete')">
                                            <a href="javascript:;">{{ item.number_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,4,'concrete')">
                                            <a href="javascript:;">{{ item.ability }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,5,'concrete')">
                                            <a href="javascript:;">{{ item.ability_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,6,'concrete')">
                                            <a href="javascript:;">{{ item.ability_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,7,'concrete')">
                                            <a href="javascript:;">{{ item.value }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,8,'concrete')">
                                            <a href="javascript:;">{{ item.value_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,9,'concrete')">
                                            <a href="javascript:;">{{ item.value_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,11,'concrete')">
                                            <a href="javascript:;">{{ item.ability_ratio }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ item.number }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.number_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.number_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_ratio }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td :key="index" v-if="index===assetUseSTData.length-1">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,11,'concrete')">
                                            <a href="javascript:;">{{ item.ability_ratio }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_ratio }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
                <tr>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <th rowspan="11" style="width:1.5rem;">
                                        砂浆产能
                                    </th>
                                    <th style="width:1.5rem;">
                                        生产线数量（条）
                                    </th>
                                </tr>
                                <tr>
                                    <th>运行（条）</th>
                                </tr>
                                <tr>
                                    <th>闲置（条）</th>
                                </tr>
                                <tr>
                                    <th>生产线能力（万吨）</th>
                                </tr>
                                <tr>
                                    <th>运行（万吨）</th>
                                </tr>
                                <tr>
                                    <th>闲置（万吨）</th>
                                </tr>
                                <tr>
                                    <th>生产线价值（万元）</th>
                                </tr>
                                <tr>
                                    <th>运行（万元）</th>
                                </tr>
                                <tr>
                                    <th>闲置（万元）</th>
                                </tr>
                                <tr>
                                    <th>总产能利用率（%）</th>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in assetUseSJData">
                        <td v-if="index<assetUseSJData.length-1" :key="index">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td @click="showDataDrills(item,1,'mortar')">
                                            <a href="javascript:;">{{ item.number }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,2,'mortar')">
                                            <a href="javascript:;">{{ item.number_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,3,'mortar')">
                                            <a href="javascript:;">{{ item.number_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,4,'mortar')">
                                            <a href="javascript:;">{{ item.ability }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,5,'mortar')">
                                            <a href="javascript:;">{{ item.ability_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,6,'mortar')">
                                            <a href="javascript:;">{{ item.ability_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,7,'mortar')">
                                            <a href="javascript:;">{{ item.value }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,8,'mortar')">
                                            <a href="javascript:;">{{ item.value_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,9,'mortar')">
                                            <a href="javascript:;">{{ item.value_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,11,'mortar')">
                                            <a href="javascript:;">{{ item.ability_ratio }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ item.number }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.number_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.number_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_ratio }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td v-else :key="index">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,11,'mortar')">
                                            <a href="javascript:;">{{ item.ability_ratio }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_ratio }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
            </tbody>
            <tbody class="border6">
                <tr>
                    <td rowspan="13" class="custom-th-odd">
                        劳动使用率
                    </td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <th rowspan="13">
                                        劳动生产率
                                    </th>
                                    <th>人员（人）</th>
                                </tr>
                                <tr>
                                    <th>运输车司机（人）</th>
                                </tr>
                                <tr>
                                    <th>商混（人）</th>
                                </tr>
                                <tr>
                                    <th>砂浆（人）</th>
                                </tr>
                                <tr>
                                    <th>销售人员（人）</th>
                                </tr>
                                <tr>
                                    <th>商混（人）</th>
                                </tr>
                                <tr>
                                    <th>砂浆（人）</th>
                                </tr>
                                <tr>
                                    <th>管理及其他（人）</th>
                                </tr>
                                <tr>
                                    <th>商混（人）</th>
                                </tr>
                                <tr>
                                    <th>砂浆（人）</th>
                                </tr>
                                <tr>
                                    <th>商砼人均运量(万方/人)</th>
                                </tr>
                                <tr>
                                    <th>商砼人均销量(万方/人)</th>
                                </tr>
                                <tr>
                                    <th>商砼人均产值(万元/人)</th>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in assetUseSCLData">
                        <td v-if="index<assetUseSCLData.length - 1" :key="index">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td @click="showDataDrills(item,1,'person')">
                                            <a href="javascript:;">{{ item.total }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,2,'person')">
                                            <a href="javascript:;">{{ item.driver_total }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,3,'person')">
                                            <a href="javascript:;">{{ item.driver_concrete }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,4,'person')">
                                            <a href="javascript:;">{{ item.driver_mortar }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,5,'person')">
                                            <a href="javascript:;">{{ item.sale_total }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,6,'person')">
                                            <a href="javascript:;">{{ item.sale_concrete }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,7,'person')">
                                            <a href="javascript:;">{{ item.sale_mortar }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,8,'person')">
                                            <a href="javascript:;">{{ item.other_total }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,9,'person')">
                                            <a href="javascript:;">{{ item.other_concrete }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,10,'person')">
                                            <a href="javascript:;">{{ item.other_mortar }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,11,'person')">
                                            <a href="javascript:;">{{ item.driver_average }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,12,'person')">
                                            <a href="javascript:;">{{ item.sale_average }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,13,'person')">
                                            <a href="javascript:;">{{ item.value_average }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ item.total }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.driver_total }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.driver_concrete }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.driver_mortar }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.sale_total }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.sale_concrete }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.sale_mortar }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.other_total }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.other_concrete }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.other_mortar }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.driver_average }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.sale_average }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value_average }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td v-else :key="index">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,11,'person')">
                                            <a href="javascript:;">{{ item.driver_average }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,12,'person')">
                                            <a href="javascript:;">{{ item.sale_average }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,13,'person')">
                                            <a href="javascript:;">{{ item.value_average }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.driver_average }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.sale_average }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value_average }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td rowspan="42" class="custom-th-even">
                        运能情况
                    </td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <th rowspan="18">
                                        罐车使用率
                                    </th>
                                    <th>罐车数量（辆）</th>
                                </tr>
                                <tr>
                                    <th>参运（辆）</th>
                                </tr>
                                <tr>
                                    <th>闲置（辆）</th>
                                </tr>
                                <tr>
                                    <th>运输能力（万方）</th>
                                </tr>
                                <tr>
                                    <th>参运（万方）</th>
                                </tr>
                                <tr>
                                    <th>闲置（万方）</th>
                                </tr>
                                <tr>
                                    <th>罐车价值（万元）</th>
                                </tr>
                                <tr>
                                    <th>参运（万元）</th>
                                </tr>
                                <tr>
                                    <th>闲置（万元）</th>
                                </tr>
                                <tr>
                                    <th>单车运输能力（方/辆*次）</th>
                                </tr>
                                <tr>
                                    <th>闲置（方/辆*次</th>
                                </tr>
                                <tr>
                                    <th>实际运输量（万方）</th>
                                </tr>
                                <tr>
                                    <th>罐车参运率（%）</th>
                                </tr>
                                <tr>
                                    <th>罐车使用率（%）</th>
                                </tr>
                                <tr>
                                    <th>运输里程（百公里）</th>
                                </tr>
                                <tr>
                                    <th>运输次数（趟）</th>
                                </tr>
                                <tr>
                                    <th>参运单车运输方量（方/辆*次）</th>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in assetUseGCData">
                        <td v-if="index<assetUseGCData.length-1" :key="index">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td @click="showDataDrills(item,1,'tanker')">
                                            <a href="javascript:;">{{ item.number }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,2,'tanker')">
                                            <a href="javascript:;">{{ item.number_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,3,'tanker')">
                                            <a href="javascript:;">{{ item.number_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,4,'tanker')">
                                            <a href="javascript:;">{{ item.ability }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,5,'tanker')">
                                            <a href="javascript:;">{{ item.ability_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,6,'tanker')">
                                            <a href="javascript:;">{{ item.ability_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,7,'tanker')">
                                            <a href="javascript:;">{{ item.value }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,8,'tanker')">
                                            <a href="javascript:;">{{ item.value_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,9,'tanker')">
                                            <a href="javascript:;">{{ item.value_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,10,'tanker')">
                                            <a href="javascript:;">{{ item.ability_single }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,12,'tanker')">
                                            <a href="javascript:;">{{ item.ability_free_single }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,13,'tanker')">
                                            <a href="javascript:;">{{ item.volume_total }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,14,'tanker')">
                                            <a href="javascript:;">{{ item.join_rate }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,15,'tanker')">
                                            <a href="javascript:;">{{ item.user_rate }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,16,'tanker')">
                                            <a href="javascript:;">{{ item.transport_mileage }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,17,'tanker')">
                                            <a href="javascript:;">{{ item.transport_number }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,18,'tanker')">
                                            <a href="javascript:;">{{ item.ability_single_use }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ item.number }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.number_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.number_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_single }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_free_single }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.volume_total }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.join_rate }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.user_rate }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.transport_mileage }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.transport_number }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_single_use }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td v-else :key="index">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
                <tr>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <th rowspan="12">
                                        泵车使用率
                                    </th>
                                    <th>泵车数量（辆）</th>
                                </tr>
                                <tr>
                                    <th>参运（辆）</th>
                                </tr>
                                <tr>
                                    <th>闲置（辆）</th>
                                </tr>
                                <tr>
                                    <th>泵送能力（万方）</th>
                                </tr>
                                <tr>
                                    <th>参运（万方）</th>
                                </tr>
                                <tr>
                                    <th>闲置（万方）</th>
                                </tr>
                                <tr>
                                    <th>泵车价值（万元）</th>
                                </tr>
                                <tr>
                                    <th>参运（万元）</th>
                                </tr>
                                <tr>
                                    <th>闲置（万元）</th>
                                </tr>
                                <tr>
                                    <th>实际运输量（万方）</th>
                                </tr>
                                <tr>
                                    <th>泵车参运率（%）</th>
                                </tr>
                                <tr>
                                    <th>泵车使用率（%）</th>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in assetUseBCData">
                        <td v-if="index<assetUseBCData.length-1" :key="index">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td @click="showDataDrills(item,1,'pumptruck')">
                                            <a href="javascript:;">{{ item.number }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,2,'pumptruck')">
                                            <a href="javascript:;">{{ item.number_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,3,'pumptruck')">
                                            <a href="javascript:;">{{ item.number_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,4,'pumptruck')">
                                            <a href="javascript:;">{{ item.ability }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,5,'pumptruck')">
                                            <a href="javascript:;">{{ item.ability_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,6,'pumptruck')">
                                            <a href="javascript:;">{{ item.ability_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,7,'pumptruck')">
                                            <a href="javascript:;">{{ item.value }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,8,'pumptruck')">
                                            <a href="javascript:;">{{ item.value_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,9,'pumptruck')">
                                            <a href="javascript:;">{{ item.value_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,10,'pumptruck')">
                                            <a href="javascript:;">{{ item.volume_total }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,11,'pumptruck')">
                                            <a href="javascript:;">{{ item.join_rate }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,12,'pumptruck')">
                                            <a href="javascript:;">{{ item.user_rate }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ item.number }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.number_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.number_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.volume_total }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.join_rate }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.user_rate }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td v-else :key="index">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
                <tr>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <th rowspan="12">
                                        铲车使用率
                                    </th>
                                    <th>铲车数量（辆）</th>
                                </tr>
                                <tr>
                                    <th>参运（辆）</th>
                                </tr>
                                <tr>
                                    <th>闲置（辆）</th>
                                </tr>
                                <tr>
                                    <th>铲运能力（万吨）</th>
                                </tr>
                                <tr>
                                    <th>参运（万吨）</th>
                                </tr>
                                <tr>
                                    <th>闲置（万吨）</th>
                                </tr>
                                <tr>
                                    <th>铲车价值（万元）</th>
                                </tr>
                                <tr>
                                    <th>参运（万元）</th>
                                </tr>
                                <tr>
                                    <th>闲置（万元）</th>
                                </tr>
                                <tr>
                                    <th>实际运输量（万吨）</th>
                                </tr>
                                <tr>
                                    <th>铲车参运率（%）</th>
                                </tr>
                                <tr>
                                    <th>铲车使用率（%）</th>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in assetUseCCData">
                        <td v-if="index<assetUseCCData.length-1" :key="index">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td @click="showDataDrills(item,1,'forklift')">
                                            <a href="javascript:;">{{ item.number }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,2,'forklift')">
                                            <a href="javascript:;">{{ item.number_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,3,'forklift')">
                                            <a href="javascript:;">{{ item.number_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,4,'forklift')">
                                            <a href="javascript:;">{{ item.ability }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,5,'forklift')">
                                            <a href="javascript:;">{{ item.ability_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,6,'forklift')">
                                            <a href="javascript:;">{{ item.ability_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,7,'forklift')">
                                            <a href="javascript:;">{{ item.value }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,8,'forklift')">
                                            <a href="javascript:;">{{ item.value_use }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,9,'forklift')">
                                            <a href="javascript:;">{{ item.value_free }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,10,'forklift')">
                                            <a href="javascript:;">{{ item.volume_total }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,11,'forklift')">
                                            <a href="javascript:;">{{ item.join_rate }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item,12,'forklift')">
                                            <a href="javascript:;">{{ item.user_rate }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ item.number }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.number_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.number_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.ability_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value_use }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.value_free }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.volume_total }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.join_rate }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.user_rate }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td v-else :key="index">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="javascript:;">—</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            —
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    // 资产使用
    name: 'asset-use',
    components: {},
    props: {
        loadObj: {
            type: [Object],
        },
        queryStr: {
            type: [String],
        },
        reportData: {
            type: [Object],
        },
    },
    data() {
        return {
            // 资产使用——商砼
            assetUseSTData: [],
            // 资产使用——铲车
            assetUseCCData: [],
            // 资产使用——砂浆
            assetUseSJData: [],
            // 资产使用——劳动生产率
            assetUseSCLData: [],
            // 资产使用——泵车
            assetUseBCData: [],
            // 资产使用——罐车
            assetUseGCData: [],
            isDrill: true,
        };
    },
    watch: {
        queryStr: function () {
            this.gitAssetUseSTData();
        },
    },
    computed: {},
    methods: {
        // 获取资产使用——商砼数据
        gitAssetUseSTData() {
            this.$axios
                .get('/interfaceApi/report/assetuse/report/concrete/' + this.queryStr)
                .then(res => {
                    this.assetUseSTData = res;
                    this.gitAssetUseCCData();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取资产使用——铲车数据
        gitAssetUseCCData() {
            this.$axios
                .get('/interfaceApi/report/assetuse/report/forklift/' + this.queryStr)
                .then(res => {
                    this.assetUseCCData = res;
                    this.gitAssetUseSJData();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取资产使用——砂浆数据
        gitAssetUseSJData() {
            this.$axios
                .get('/interfaceApi/report/assetuse/report/mortar/' + this.queryStr)
                .then(res => {
                    this.assetUseSJData = res;
                    this.gitAssetUseSCLData();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取资产使用——劳动生产率数据
        gitAssetUseSCLData() {
            this.$axios
                .get('/interfaceApi/report/assetuse/report/person/' + this.queryStr)
                .then(res => {
                    this.assetUseSCLData = res;
                    this.gitAssetUseGCData();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取资产使用——泵车数据
        gitAssetUseGCData() {
            this.$axios
                .get('/interfaceApi/report/assetuse/report/pumptruck/' + this.queryStr)
                .then(res => {
                    this.assetUseBCData = res;
                    this.gitAssetUseBCData();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取资产使用——罐车数据
        gitAssetUseBCData() {
            this.$axios
                .get('/interfaceApi/report/assetuse/report/tanker/' + this.queryStr)
                .then(res => {
                    this.assetUseGCData = res;
                    // this.loadObj.close();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 数据钻取
        // eslint-disable-next-line complexity
        showDataDrills(rowData, field, name) {
            let parsetText = null;
            if (name === 'concrete' || name === 'mortar') {
                switch (field) {
                    case 1:
                        parsetText = ' 生产线数量（条）';
                        break;
                    case 2:
                        parsetText = '运行（条）';
                        break;
                    case 3:
                        parsetText = '闲置（条）';
                        break;
                    case 4:
                        parsetText = '生产线能力（万方）';
                        break;
                    case 5:
                        parsetText = '运行（万方）';
                        break;
                    case 6:
                        parsetText = '闲置（万方）';
                        break;
                    case 7:
                        parsetText = '生产线价值（万元）';
                        break;
                    case 8:
                        parsetText = '运行（万元）';
                        break;
                    case 9:
                        parsetText = '闲置（万元）';
                        break;
                    case 10:
                        parsetText = '总产能利用率（%）';
                        break;
                }
            }
            if (name === 'person') {
                switch (field) {
                    case 1:
                        parsetText = ' 人员（人）';
                        break;
                    case 2:
                        parsetText = '运输车司机（人）';
                        break;
                    case 3:
                        parsetText = '商混（人）';
                        break;
                    case 4:
                        parsetText = '砂浆（人）';
                        break;
                    case 5:
                        parsetText = '销售人员（人）';
                        break;
                    case 6:
                        parsetText = '商混（人）';
                        break;
                    case 7:
                        parsetText = '砂浆（人）';
                        break;
                    case 8:
                        parsetText = '管理及其他（人）';
                        break;
                    case 9:
                        parsetText = '商混（人）';
                        break;
                    case 10:
                        parsetText = '砂浆（人）';
                        break;
                    case 11:
                        parsetText = '商砼人均运量(万方/人)';
                        break;
                    case 12:
                        parsetText = '商砼人均销量(万方/人)';
                        break;
                    case 13:
                        parsetText = '商砼人均产值(万元/人)';
                        break;
                }
            }
            if (name === 'tanker') {
                switch (field) {
                    case 1:
                        parsetText = '罐车数量（辆）';
                        break;
                    case 2:
                        parsetText = '参运（辆）';
                        break;
                    case 3:
                        parsetText = '闲置（辆）';
                        break;
                    case 4:
                        parsetText = '运输能力（万方）';
                        break;
                    case 5:
                        parsetText = '参运（万方）';
                        break;
                    case 6:
                        parsetText = '闲置（万方）';
                        break;
                    case 7:
                        parsetText = '罐车价值（万元）';
                        break;
                    case 8:
                        parsetText = '参运（万元）';
                        break;
                    case 9:
                        parsetText = '闲置（万元）';
                        break;
                    case 10:
                        parsetText = '单车运输能力（方/辆*次）';
                        break;
                    case 12:
                        parsetText = '实际运输量（万方）';
                        break;
                    case 13:
                        parsetText = '罐车参运率（%）';
                        break;
                    case 14:
                        parsetText = '罐车使用率（%）';
                        break;
                    case 15:
                        parsetText = '运输里程（百公里）';
                        break;
                    case 16:
                        parsetText = '运输次数（趟）';
                        break;
                    case 17:
                        parsetText = '参运单车运输方量（方/辆*次）';
                        break;
                }
            }
            if (name === 'pumptruck') {
                switch (field) {
                    case 1:
                        parsetText = '泵车数量（辆）';
                        break;
                    case 2:
                        parsetText = '参运（辆）';
                        break;
                    case 3:
                        parsetText = '闲置（辆）';
                        break;
                    case 4:
                        parsetText = '泵送能力（万方）';
                        break;
                    case 5:
                        parsetText = '参运（万方）';
                        break;
                    case 6:
                        parsetText = '闲置（万方）';
                        break;
                    case 7:
                        parsetText = '泵车价值（万元）';
                        break;
                    case 8:
                        parsetText = '参运（万元）';
                        break;
                    case 9:
                        parsetText = '闲置（万元）';
                        break;
                    case 10:
                        parsetText = '实际运输量（万方）';
                        break;
                    case 11:
                        parsetText = '泵车参运率（%）';
                        break;
                    case 12:
                        parsetText = '泵车使用率（%）';
                        break;

                }
            }
            if (name === 'forklift') {
                switch (field) {
                    case 1:
                        parsetText = '铲车数量（辆）';
                        break;
                    case 2:
                        parsetText = '参运（辆）';
                        break;
                    case 3:
                        parsetText = '闲置（辆）';
                        break;
                    case 4:
                        parsetText = '铲运能力（万吨）';
                        break;
                    case 5:
                        parsetText = '参运（万吨）';
                        break;
                    case 6:
                        parsetText = '闲置（万吨）';
                        break;
                    case 7:
                        parsetText = '铲车价值（万元）';
                        break;
                    case 8:
                        parsetText = '参运（万元）';
                        break;
                    case 9:
                        parsetText = '闲置（万元）';
                        break;
                    case 10:
                        parsetText = '实际运输量（万吨）';
                        break;
                    case 11:
                        parsetText = '铲车参运率（%）';
                        break;
                    case 12:
                        parsetText = '铲车使用率（%）';
                        break;

                }
            }
            let istotal = 0;
            let month = 0;
            if (rowData.date === 0) {
                istotal = 1;
                if (this.reportData.month === 0) {
                    month = 12;
                } else {
                    month = this.reportData.month;
                }
            } else {
                istotal = 0;
                month = rowData.date;
            }
            const params = {
                field: field,
                text: parsetText,
                year: this.reportData.year,
                month: month,
                business_code: 'yyqk',
                istotal: istotal,
                treeid: this.reportData.treeid,
                firstPop: true,
                sourceReport: name,
            };
            const pop = this.$toast({
                title: false,
                text: '按各企业上报数据钻取分析',
                t_url: 'reportManage/operationTrends/summary/dataDrills/DrillDownPop',
                type: 'eject',
                width: '80%',
                extr: { closeBtn: { width: '.6rem', height: '.6rem', 'line-height': '.6rem' }, params: params },
            });
            window.window.$globalHub.$store.commit('setChartPop', pop);
        },
        isDrillFn() {
            if (this.reportData.org_type === 5 || this.reportData.org_type === 6) {
                this.isDrill = false;
            } else {
                this.isDrill = true;
            }
        },
    },
    created() {
        this.gitAssetUseSTData();
    },
    mounted() {
        this.isDrillFn();
    },
};
</script>
<style lang="stylus">
.assetUse
    table
        table
            width 100%
            height 100%
            border-collapse collapse
            table-layout fixed
            word-break break-all
            tr
                &:last-child
                    th
                        border none !important
                    td
                        border none !important
                th
                    border none !important
                    border-bottom 1px solid #ebeef5 !important
                    font-weight 400
                    line-height initial !important
                    &:nth-of-type(1)
                        border-right 1px solid #ebeef5 !important
                td
                    border none !important
                    border-bottom 1px solid #ebeef5 !important
                    line-height initial !important
</style>